.dropdow-menu {
  width: 180px;
  position: absolute;
  list-style: none;
  text-align: start;
}

.dropdow-menu li {
  background: rgb(254, 254, 254) 0%;
  cursor: pointer;
}

.dropdow-menu li:hover {
  background: #00396b;
}

.dropdow-menu.clicked {
  display: none;
}

.dropdow-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}
.dropdow-link:hover {
  color: #fff;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
