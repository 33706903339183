.banner {
  position: relative;
  width: 100%;
  height: 100vh;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: white;
}

.heading {
  font-size: 3rem;
  margin: 0 0 1rem;
}

.subheading {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 50px;
}

.contact-frame {
  height: 300px;
  position: absolute;
  top: 55%;
  right: 5%;
  transform: translateY(-50%);
  width: 15%;
  padding: 2rem;
  color: white;
  border-style: solid;
  border-radius: 20px;
  border-color: #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form {
  background-color: rgb(206, 203, 203);
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  width: 25%;
  padding: 2rem;
  color: rgb(255, 255, 255);
  /* border-style: solid; */
  border-radius: 10px;
  border-color: #363535;
  flex-direction: column;
  align-items: center;
}
.TalkCard {
  border-radius: 6px;
  padding: 0 16px;
  bottom: 23%;
}
.TalkCard img {
  width: 100px;
  margin-bottom: 2em;
}

.form-heading {
  color: #363535;
  font-size: 2rem;
  margin: 0 0 2rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin: 0 0 1rem;
  padding: 5px;
}

input,
textarea {
  /* padding: 0.5rem; */
  font-size: 1rem;
  border: 1px solid #e6e4e4;
  border-radius: 4px;
  resize: vertical;
  width: 100%;
}

textarea {
  min-height: 100px;
  resize: none;
}

.C2ABtn {
  text-decoration: none;
  text-align: center;
  position: absolute;
  width: 200px;
  bottom: 30px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 10px;
  background-color: #54173b;
  color: white;
  cursor: pointer;
}
.C2ABtn:hover {
  color: #54173b;
  background-color: white;
}

.contactBtn {
  padding: 5px;
  margin-top: 10px;
  width: 100%;
  border-radius: 4px;
  background-color: #00396b;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .banner {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .text-container {
    width: 100%;
    padding: 0 2rem;
    text-align: center;
  }

  .contact-form {
    visibility: hidden;
  }
}

.cta-container {
  position: relative;
  display: inline-block;
}
.cta-container:before {
  content: '';
  position: absolute;
  top: 50%;
  right: -14px;
  transform: translateY(-50%) rotate(45deg);
  border-top: 28px solid #6747c7;
  border-right: 28px solid #6747c7;
  width: 0;
  height: 0;
}

/* //handle click */

.contact-us-bubble {
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: #005599;
  color: #ffffff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.contact-us-bubble:hover {
  background-color: #026718;
}

.contact-frm {
  position: fixed;
  padding: 25px;
  bottom: 110px;
  right: 20px;
  background-color: #f0eaea;
  color: #333333;
  border-radius: 5px;
  width: 350px;
  height: 450px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.contactIcon {
  height: 80px;
  width: 80px;
}
