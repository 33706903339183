* {
  margin: 0;
  padding: 0;
  font-family: manrope;
}

.Container {
  margin: 100px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.Container h1 {
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #54173b;
}
.Container h2 {
  font-family: 'Poppins', Sans-serif;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
.Container ul li {
  font-size: 1.5em;
}
