@media only screen and (min-width: 1024px) {
}
.hiiDHD {
  margin-top: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
  font-size: larger;
}

.nav-link {
  font-size: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-linkedin {
  background: #0e76a8;
  border-radius: 10px;
  color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #084461;
}

.btn-linkedin:link,
.btn-linkedin:visited {
  color: #fff;
}

.btn-linkedin:active,
.btn-linkedin:hover {
  background: #084461;
  color: #fff;
}

.profileImg {
  border-radius: 50%;
  width: 250px;
}

.linkedinprofile {
  width: 200px;
}

.aboutImg {
  width: 371px;
  height: 255px;
}

.partners {
  width: 200px;
  height: auto;
}

.table td {
  text-align: center;
}

.navbar-brand {
  margin: 0;
  padding: 50;
}
.navbar-nav {
  font: normal normal 800 16px/22px Manrope;
}

.active {
  color: #54173b;
}

/* WhatsApp Floating Button CSS */
.whatsapp-float {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 100px;
  right: 100px;
  /* background-color: #25d366; */
  /* color: #fff; */
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 1000;
}

.whatsapp-icon {
  /* margin-top: 10px; */
  width: 60px;
  height: 60px;
}
