.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 90vw;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 20px;
  list-style: none;
}

.navbar-nav li {
  margin: 0 10px;
  align-items: center;
}

.navbar-nav a {
  color: rgb(12, 12, 12);
  text-decoration: none;
}

.navbar-nav .active {
  color: #7b1845;
}
.navbar {
  margin-top: 0px;
}

@media (max-width: 767px) {
  .navbar {
    margin-top: 0px;
  }
  /* * {
    overflow-x: hidden;
  } */
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: flex-end;
    margin-right: auto;
    margin-left: auto;
  }
  .nav-sub {
    overflow: visible;
  }

  .get-q-btn {
    display: none;
  }
}

ul {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  margin-right: 15px;
  position: relative;
}

.sub-menu {
  list-style: none;
  text-align: start;
  display: none;
  position: absolute;
  top: 100%;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.sub-menu li {
  padding: 10px;
  align-content: center;
}

.sub-menu li:hover {
  background: #7b1845;
}

li:hover .sub-menu {
  display: block;
}

.btn-dark-blue {
  width: 150px;
  height: 50px;
  background: #7474bf; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #348ac7,
    #7474bf
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #348ac7,
    #7474bf
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
  border: 3px solid #eee;
  border-radius: 35px;
}
