@import url('https://fonts.googleapis.com/css2?family=Mulish&family=Roboto&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'Urbanist', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .navbar-brand {
    font-size: 2rem;
    color: #565387;
} */

.navbar {
  background-color: #fff;
}
